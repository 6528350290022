<template>
  <nav
    v-if="variants.HEADER_1 === variant"
    :class="[
      'tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-p-6 tw-text-center tw-duration-300 xl:tw-justify-around',
      'tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-w-full tw-border-b',
    ]"
    :style="`
      ${
        isScrolled || vars.fixedColorBool || menuVisible
          ? `background-color: var(--c-${vars.backgroundColor});`
          : 'background-color: transparent;'
      }
      color: var(--c-${vars.textColor});
  `"
  >
    <div class="tw-max-w-48 tw-justify-center xl:tw-max-w-60">
      <nuxt-link style="all: unset !important" to="/" :external="true">
        <NuxtImg :src="logo" :alt="company.name" class="tw-cursor-pointer" />
      </nuxt-link>
    </div>
    <div class="tw-z-40 tw-flex tw-justify-start tw-pt-2 xl:tw-hidden">
      <button id="menu-button" class="focus:outline-none tw-flex" @click="toggleMenu">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.5 27V24H31.5V27H4.5ZM4.5 19.5V16.5H31.5V19.5H4.5ZM4.5 12V9H31.5V12H4.5Z" fill="white" />
        </svg>
      </button>
    </div>
    <div class="tw-hidden xl:tw-block">
      <div class="tw-ml-4 tw-hidden xl:tw-block">
        <ul class="tw-flex tw-items-center tw-gap-6 tw-align-middle lg:tw-shrink">
          <li
            v-for="(menuGroup, groupIndex) in allMenu[0]"
            :key="`menuGroupIndex-${groupIndex}`"
            class="tw-group tw-relative tw-py-5"
          >
            <div class="tw-flex tw-items-center tw-justify-center">
              <nuxt-link
                :external="true"
                :to="menuGroup.url"
                :target="menuGroup.is_open_new_tab ? '_blank' : ''"
                class="n-link"
              >
                {{ menuGroup.name }}
              </nuxt-link>
              <span v-if="menuGroup.has_sub_menu" class="nc-icon-chevron-down sub-menu-arrow tw-ml-2"></span>
            </div>
            <ul
              v-if="menuGroup.sub_menus && menuGroup.sub_menus.length && menuGroup.has_sub_menu"
              class="tw-absolute tw-mt-3 tw-hidden tw-rounded-sm tw-px-6 tw-py-4 tw-text-left tw-text-white tw-shadow-md group-hover:!tw-block"
              :style="`background-color: var(--c-primary); border-radius: var(--rounded-sm);`"
            >
              <li
                v-for="(subMenu, subMenuIndex) in menuGroup.sub_menus"
                :key="`${subMenuIndex}-subMenu-${groupIndex}`"
                class="tw-w-[220px] tw-py-4"
                style="border-color: var(--c-border)"
                :class="{
                  'tw-border-b': subMenuIndex !== menuGroup.sub_menus.length - 1,
                }"
              >
                <nuxt-link
                  class="n-link tw-block tw-px-4 tw-py-2 hover:tw-ml-4"
                  :external="true"
                  :to="subMenu.url"
                  :target="subMenu.is_open_new_tab ? '_blank' : ''"
                >
                  {{ subMenu.name }}
                </nuxt-link>
              </li>
            </ul>
          </li>
          <li>
            <nuxt-link to="/contact-us" class="n-outlined" :external="true">GET IN TOUCH</nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div
    v-if="variants.HEADER_1 === variant"
    class="tw-fixed -tw-top-[2000px] tw-left-0 tw-z-30 tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-overflow-y-auto tw-duration-300"
    :class="menuVisible === true ? '!tw-top-0' : '-tw-top-[1000px]'"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: var(--c-${vars.textColor});`"
  >
    <ul class="tw-mt-40 tw-flex tw-flex-col tw-items-center tw-pb-10">
      <li
        v-for="(menuGroup, groupIndex) in allMenu[0]"
        :key="`menuGroupIndex-${groupIndex}`"
        class="tw-group tw-relative tw-py-5"
      >
        <div class="tw-flex tw-items-center tw-justify-center">
          <nuxt-link
            :external="true"
            :to="menuGroup.url"
            :target="menuGroup.is_open_new_tab ? '_blank' : ''"
            class="n-link"
          >
            {{ menuGroup.name }}
          </nuxt-link>
          <span
            v-if="menuGroup.has_sub_menu"
            class="nc-icon-chevron-down sub-menu-arrow theme3-menu-link tw-ml-2"
          ></span>
        </div>
        <ul
          v-if="menuGroup.sub_menus && menuGroup.sub_menus.length && menuGroup.has_sub_menu"
          class="tw-absolute tw-z-50 tw-mt-2 tw-hidden tw-rounded-sm tw-px-6 tw-py-4 tw-text-left tw-text-white tw-shadow-md group-hover:!tw-block"
          :style="`background-color: var(--c-primary); border-radius: var(--rounded-sm);`"
        >
          <li
            v-for="(subMenu, subMenuIndex) in menuGroup.sub_menus"
            :key="`${subMenuIndex}-subMenu-${groupIndex}`"
            class="tw-w-[150px] tw-py-4 md:tw-w-[220px]"
            style="border-color: var(--c-border)"
            :class="{
              'tw-border-b': subMenuIndex !== menuGroup.sub_menus.length - 1,
            }"
          >
            <nuxt-link
              class="n-link tw-block tw-px-4 tw-py-2 hover:tw-ml-4"
              :external="true"
              :to="subMenu.url"
              :target="subMenu.is_open_new_tab ? '_blank' : ''"
            >
              {{ subMenu.name }}
            </nuxt-link>
          </li>
        </ul>
      </li>
      <li class="tw-pt-5">
        <nuxt-link :external="true" to="/contact-us" class="n-outlined">GET IN TOUCH</nuxt-link>
      </li>
    </ul>
  </div>

  <div
    v-if="variants.HEADER_2 === variant"
    class="position-unset navbar tw-absolute tw-left-0 tw-top-0 tw-z-50 tw-flex tw-w-full tw-items-center tw-justify-between !tw-bg-opacity-90 tw-px-6 tw-py-4 tw-transition-all tw-duration-300 md:tw-px-20 xl:tw-px-32"
    :class="{ 'scrolled !tw-fixed': isScrolled }"
    :style="`
      ${
        isScrolled || vars.fixedColorBool
          ? `background-color: var(--c-${vars.backgroundColor}); color: var(--c-text-primary);`
          : 'background-color: transparent;'
      }, ${vars.fixedColorBool ? `color: var(--c-text-primary);` : ''}
      color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary) '};
      z-index: 1001;
    `"
  >
    <nuxt-link to="/" :external="true" class="n-link tw-max-w-6">
      <div class="tw-w-24">
        <LibraryImage :src="logo" :h-ratio="1" :w-ratio="2" />
      </div>
    </nuxt-link>
    <div class="tw-flex tw-items-center tw-gap-6">
      <nuxt-link
        v-if="vars.buttonLinkUrl"
        :to="vars.buttonLinkUrl"
        :external="true"
        :class="`n-${vars.buttonTypeText} tw-hidden sm:tw-block`"
        >{{ vars.buttonLabelText }}
      </nuxt-link>
      <button
        class="icon burger-menu"
        @click="toggleActiveClass"
        :style="`${vars.fixedColorBool ? `color: var(--c-${vars.navTextColor}) !important` : ' color: var(--white)'};`"
      >
        <span
          :style="`background: ${isScrolled ? 'white' : vars.fixedColorBool ? 'white' : `var(--c-${vars.navTextColor})`}`"
        ></span>
        <span
          :style="`background: ${isScrolled ? 'white' : vars.fixedColorBool ? 'white' : `var(--c-${vars.navTextColor})`}`"
        ></span>
        <span
          :style="`background: ${isScrolled ? 'white' : vars.fixedColorBool ? 'white' : `var(--c-${vars.navTextColor})`}`"
        ></span>
      </button>
    </div>
  </div>
  <div
    v-if="variants.HEADER_2 === variant"
    class="nav-menu tw-fixed tw-inset-0 tw-z-40 tw-overflow-auto !tw-bg-opacity-90 tw-px-5 md:tw-px-14 xl:tw-px-28"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--white)'}`"
  >
    <div class="tw-mt-32 tw-flex tw-w-full tw-flex-col tw-justify-start tw-gap-6 tw-py-10 lg:tw-flex-row lg:tw-gap-20">
      <ul class="tw-flex tw-flex-col tw-gap-6">
        <li
          v-for="(menuGroup, groupIndex) in leftMenu"
          :key="`menuGroupIndex-${groupIndex}`"
          class="nav-item tw-group tw-relative tw-duration-300"
          :style="`animation-delay: ${groupIndex * 0.2}s;`"
        >
          <nuxt-link
            :to="menuGroup.has_sub_menu ? '' : menuGroup.url"
            :target="menuGroup.is_open_new_tab ? '_blank' : ''"
            :external="true"
            class="n-link tw-duration-400 tw-text-start hover:tw-text-[var(--c-secondary)]"
          >
            <h2>{{ menuGroup.name }}</h2>
          </nuxt-link>
        </li>
      </ul>
      <ul class="tw-flex tw-flex-col tw-gap-6">
        <li
          v-for="(menuGroup, groupIndex) in rightMenu"
          :key="`menuGroupIndex-${groupIndex}`"
          class="nav-item tw-group tw-relative tw-duration-300"
          :style="`animation-delay: ${groupIndex * 0.2}s;`"
        >
          <nuxt-link
            :to="menuGroup.has_sub_menu ? '' : menuGroup.url"
            :target="menuGroup.is_open_new_tab ? '_blank' : ''"
            :external="true"
            class="n-link tw-duration-400 tw-text-start hover:tw-text-[var(--c-secondary)]"
          >
            <h2>{{ menuGroup.name }}</h2>
          </nuxt-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { mapState } from 'pinia';
import { MenuTypes, ViewTypes } from '~/constants/ui.constants';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useWebsiteStore } from '~/units/website/store';
import { useCoreStore } from '~/units/core/store';

type Menu = {};

export default defineNuxtComponent({
  name: 'Theme5BaseHeaderOne',

  mixins: [ComponentMixin],

  props: {
    component: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      ViewTypes,
      menuVisible: false,
      isScrolled: false,
      openSubMenu: null,
      variants: {
        HEADER_1: 'HEADER_1',
        HEADER_2: 'HEADER_2',
      },
    };
  },

  computed: {
    ...mapState(useWebsiteStore, ['headerMenuItems']),
    ...mapState(useCoreStore, ['isAnythingOnTopVisible']),

    headerMenu() {
      return this.headerMenuItems as Menu[];
    },

    routeUrl() {
      const route = useRoute();
      return route.path;
    },

    leftMenu() {
      return this.allMenuV2[0].slice(0, 7);
    },
    rightMenu() {
      return this.allMenuV2[0].slice(7);
    },

    allMenu() {
      const period = 9;
      const columnsCount = Math.ceil(this.columnOneMenu.length / period);
      const groups = [];

      for (let i = 0; i < columnsCount; i++) {
        groups[i] = [];
        for (let p = 0; p < period; p++) {
          const index = period * i + p;
          if (this.columnOneMenu[index]) {
            groups[i].push(this.columnOneMenu[index]);
          }
        }
      }

      return groups;
    },

    allMenuV2() {
      const period = 20;
      const columnsCount = Math.ceil(this.columnOneMenu.length / period);
      const groups = [];

      for (let i = 0; i < columnsCount; i++) {
        groups[i] = [];
        for (let p = 0; p < period; p++) {
          const index = period * i + p;
          if (this.columnOneMenu[index]) {
            groups[i].push(this.columnOneMenu[index]);
          }
        }
      }

      return groups;
    },

    logo() {
      if (this.menuVisible) {
        return this.company.light_logo || this.company.logo;
      } else if (this.isScrolled) {
        return this.company.logo;
      } else if (!this.vars.fixedColorBool && !this.isScrolled) {
        return this.company.light_logo || this.company.logo;
      }
      return this.company.logo;
    },

    columnOneMenu() {
      const content = this.component.variables['column_one_content-text'];
      if (content === MenuTypes.HEADER_MENU) {
        return this.headerMenu;
      }
      return [];
    },
  },

  methods: {
    toggleSubMenu(index) {
      this.openSubMenu = this.openSubMenu === index ? null : index;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },

    toggleBurgerMenu() {
      if (!this.menuVisible) {
        const navItems = document.querySelectorAll('.nav-item');
        navItems.forEach((item, index) => {
          setTimeout(() => {
            item.classList.add('nav-item-visible');
          }, index * 100);
        });
      } else {
        const navItems = document.querySelectorAll('.nav-item');
        navItems.forEach((item, index) => {
          setTimeout(
            () => {
              item.classList.remove('nav-item-visible');
            },
            (navItems.length - index) * 100,
          );
        });
        setTimeout(() => {
          this.menuVisible = false;
          document.body.classList.remove('burger-menu-visible');
          document.body.classList.remove('no-scroll');
        }, navItems.length * 50);
        return;
      }

      this.menuVisible = !this.menuVisible;
      document.body.classList.toggle('burger-menu-visible', this.menuVisible);
      if (this.menuVisible && this.variants.HEADER_2 === this.variant) {
        document.body.classList.add('no-scroll');
      } else {
        document.body.classList.remove('no-scroll');
      }
    },

    handleScroll() {
      this.isScrolled = window.scrollY > (this.variant === this.variants.HEADER_2 ? 400 : 0);
    },

    toggleActiveClass() {
      const burgerMenu = document.querySelector('.burger-menu');
      burgerMenu.classList.toggle('active');
      burgerMenu.querySelectorAll('span').forEach((span) => {
        span.classList.toggle('active');
      });
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();

    if (this.variants.HEADER_2 === this.variant) {
      const burgerMenu = document.querySelector('.burger-menu');

      if (burgerMenu) {
        burgerMenu.addEventListener('click', (e) => {
          e.preventDefault();
          this.toggleBurgerMenu();
        });
      }
    }

    if (this.menuVisible) {
      document.body.classList.add('no-scroll');
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    document.body.classList.remove('no-scroll');
  },
});
</script>

<style>
.no-scroll {
  overflow: hidden;
}
</style>

<style scoped>
.nav-menu {
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}

.navbar {
  transition: all 0.5s ease-in-out;
}
.nav-item {
  transform: translateY(-20px);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.nav-item-visible {
  transform: translateY(0);
  opacity: 1;
}

.burger-menu-visible .nav-menu {
  opacity: 1;
  pointer-events: initial;
}

.burger-menu-visible .navbar {
  background-color: var(--c-primary) !important;
}

button.icon {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: transparent;
  cursor: pointer;
  align-items: center;
  width: 56px;
  height: 56px;
  justify-content: center;
}

button.icon > span {
  height: 3px;
  width: 22px;
  background: inherit;
  transition: all 0.3s linear;
}

button.icon > span:last-child {
  height: 3px;
}

/* Hover effects */
button.icon:hover > span:first-child {
  width: 11px;
  margin-left: 11px;
}

button.icon.active > span:last-child {
  opacity: 0;
}

button.icon.active:hover > span:first-child {
  width: 22px;
  margin-left: 0;
}

button.icon.active > span {
  transform: rotate(45deg);
  margin-bottom: -3px;
}

button.icon.active > span.active {
  background: #fff !important;
}

button.icon.active > span:nth-child(2) {
  width: 22px;
  margin-left: 0;
  margin-top: -5px;
  transform: rotate(-45deg);
  margin-bottom: 0;
}

button.icon span {
  transition: 0.3s ease-in-out;
}

.scrolled {
  animation: fadeIn 0.8s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: -50px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}
</style>

<template>
  <section
    v-if="variants.v1 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-text-black">
      <div
        v-html="vars.titleHtml"
        class="tw-mb-10 tw-mr-auto tw-flex tw-items-start tw-justify-start tw-gap-3 tw-text-start"
      ></div>
      <div class="tw-flex tw-flex-col tw-gap-6 lg:tw-flex-row">
        <div
          v-for="(reason, index) in reasons"
          :key="`whysection-reason-${index}`"
          class="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4 tw-bg-white tw-p-7 tw-text-center"
        >
          <nuxt-img :src="reason.icon" class="tw-max-w-16 lg:tw-w-20 lg:tw-max-w-none" />
          <h4 class="tw-mb-4 tw-h-1/6">{{ reason.title }}</h4>
          <p class="tw-line-clamp-[10] lg:tw-min-h-[180px]">
            {{ reason.description }}
          </p>
          <nuxt-link
            v-if="reason.button_link"
            :to="reason.button_link"
            :class="`n-${reason.button_type} tw-mt-auto`"
            style="color: var(--c-primary)"
            ><h5>{{ reason.button_label }}</h5></nuxt-link
          >
        </div>
      </div>
    </div>
  </section>

  <section
    v-if="variants.v2 === variant"
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="n-title-primary tw-text-center">
      <div v-html="vars.titleHtml" class="tw-mx-auto tw-mb-12"></div>
      <div class="tw-flex tw-flex-col tw-gap-6 lg:tw-flex-row">
        <MotionGroup
          preset="popVisibleOnce"
          :duration="800"
          is="div"
          v-for="(reason, index) in reasons"
          :key="`whysection-reason-${index}`"
          class="tw-relative tw-flex tw-w-full tw-max-w-xl tw-flex-col tw-items-center tw-gap-x-4 tw-gap-y-6 tw-overflow-hidden tw-border-b-2 tw-bg-white tw-p-7 tw-text-center tw-transition-all tw-duration-500 after:tw-absolute after:tw-bottom-[-600px] after:tw-left-0 after:tw-z-0 after:tw-h-full after:tw-w-full after:tw-bg-[var(--c-input)] after:tw-duration-500 after:tw-content-[''] hover:tw-translate-y-[-5px] hover:tw-shadow-[var(--box_shadow)] hover:after:tw-bottom-0 lg:tw-min-h-[424px]"
          style="
            border-color: var(--c-secondary);
            border-top-left-radius: var(--rounded);
            border-top-right-radius: var(--rounded);
          "
        >
          <nuxt-img :src="reason.icon" class="tw-z-10 tw-max-w-16 lg:tw-w-20 lg:tw-max-w-none" />
          <h4 class="n-title-primary tw-z-10 tw-mb-4 tw-h-1/6 !tw-font-normal">{{ reason.title }}</h4>
          <p class="tw-z-10 tw-line-clamp-[10] tw-text-black">
            {{ reason.description }}
          </p>
        </MotionGroup>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { MotionGroup } from '#components';
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'WhySection',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },
  computed: {
    reasons() {
      return this.groupedVariables.reasons;
    },
  },
});
</script>
